import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../partials/Loading';
import _ from 'lodash';
import { TuDanhGia_Admin, TuDanhGia_User, BaoCaoHoatDong_Admin, BaoCaoHoatDong_User, ChamDiemHoatDong_Admin, ChamDiemHoatDong_User } from '../partials/Home/Home_Admin';

const Home = () => {

  const [baocao, setBaocao] = useState(false)
  const [err, setErr] = useState(false)

  const [years, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(false);

  const fetchYears = async () => {
    return await axios.get('/api/period/list_years')
  }

  useEffect(() => {

    fetchYears()
      .then(response => {

        setBaocao(false)

        const currentY = _.find(response.data, function (item) {

          return item.current == 1;
        });

        setYearList(response.data);

        if (!selectedYear) setSelectedYear(currentY.nam)
      })
      .then(() => {

        if (selectedYear)
          fetchBaocao(selectedYear)
            .then(result => setBaocao(result.data))
      }
      )
  }, [selectedYear])

  const fetchBaocao = year => {
    return axios.get('/api/baocao_tonghop', {
      params: {
        year: year
      }
    })
  }

  const Owner = (ct, CA, CU) => {

    return sessionStorage.getItem('role_id') == 3 || sessionStorage.getItem('donvi_id') == ct.donvi_id ? <CA ct={ct} /> : <CU ct={ct} />
  }

  if (!baocao) return <Loading />;

  return (
    <div className="container section-main py-3">
      <div className="row">
        <div className="col-2 d-flex align-items-center justify-content-center">
          <strong>Chọn năm báo cáo</strong>
        </div>
        <div className="col-2">
          <select defaultValue={selectedYear} onChange={e => setSelectedYear(e.target.value)} className="form-select">
            {years.map((item, i) => <option key={i} value={item.nam}>{item.nam}</option>)}
          </select>
        </div>
      </div>
      {baocao.map((item, i) => <div className="card my-3 shadow-sm" key={i}>
        <h5 className="card-header">Tháng {item.month}</h5>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead key={i}>
                <tr>
                  <th width={50} className="text-center">STT</th>
                  <th width="30%">Đơn vị</th>
                  <th width="17.5%" className="text-center">Tự đánh giá</th>
                  <th width="17.5%" className="text-center">Thẩm định</th>
                  <th width="17.5%" className="text-center">Báo cáo<br />Hoạt động ATVSV</th>
                  <th width="17.5%" className="text-center">Chấm điểm đánh giá hoạt động ATVSV</th>
                </tr>
              </thead>
              <tbody>
                {item.data.map((ct, i) => <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td>{ct.ten_donvi}</td>
                  <td className="text-center">
                    {Owner(ct, TuDanhGia_Admin, TuDanhGia_User)}
                  </td>
                  <td className="text-center">
                    {ct.sum_diemtothamdinh ?? '--'}
                  </td>
                  <td className="text-center">
                    {Owner(ct, BaoCaoHoatDong_Admin, BaoCaoHoatDong_User)}
                  </td>
                  <td className="text-center">
                    {Owner(ct, ChamDiemHoatDong_Admin, ChamDiemHoatDong_User)}
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
          {item.chua_danhgia && <div style={{ fontSize: 13 }} className="mb-2"> <strong>Đơn vị chưa thực hiện tự đánh giá:</strong> {item.chua_danhgia.split(',').map(cdg => <div key={cdg}>- {cdg}</div>)}</div>}
        </div>
      </div>)}
    </div>
  );
};

export default Home;