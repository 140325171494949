import axios from 'axios';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../partials/Loading';

const Thamdinh = () => {

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [params])

  const [categories, setCategories] = useState([])
  const [sent, setSent] = useState(false)
  const [period, setPeriod] = useState(false)
  const [ketqua, setKetQua] = useState([])
  const [form, setForm] = useState({
    noidung: [],
    user_id: sessionStorage.getItem('user_id'),
  })
  const [dapheduyet, setDapheduyet] = useState([])
  const [thuchien, setThucHien] = useState({
    ten_ky: false,
    donvi_thuchien: false,
  })

  const [btnClick, setBtnClick] = useState(false);

  const fetchCategories = async () => {
    const categories = await axios.get('/api/dm_tieuchi')
    const period = await axios.get('/api/period')
    const categoryDetails = await axios.get('/api/dm_chitiettieuchi')
    const detailContents = await axios.get('/api/dm_noidung')

    return {
      categories: categories.data,
      categoryDetails: categoryDetails.data,
      detailContents: detailContents.data,
      period: period.data
    }
  }

  const checkDadanhgia = () => {
    return sent.dadanhgia && sent.dadanhgia.find(x => x === params.donvi)
  }

  const checkThamdinh = () => {
    return sent.dathamdinh && sent.dathamdinh.find(x => x === params.donvi)
  }

  const fetchThucHien = async () => {
    const ky = await axios.get('/api/period/' + params.ky)
    const donvi = await axios.get('/api/donvi/' + params.donvi)
    return {
      ky: ky,
      donvi: donvi
    }
  }

  const fetchKetQua = () => {
    return axios.get('/api/ketqua/filter', {
      params: {
        donvi_id: params.donvi,
        ky_id: params.ky,
      }
    })
  }

  const handleSetForm = (e, id, danhmuc_id, chitiet_id) => {

    if (e.target.value > 2) {
      e.target.value = 2
    }

    if (e.target.value < 1) {
      e.target.value = 1
    }

    const newArray = form.noidung
    const exist = _.findIndex(newArray, { noidung_id: id })

    if (exist >= 0) {
      newArray[exist].so_diem = e.target.value
      setForm(form => ({ ...form, noidung: newArray }))
    } else {
      setForm(form => ({
        ...form, noidung: [...form.noidung, {
          noidung_id: id,
          so_diem: e.target.value,
          danhmuc_id: danhmuc_id,
          chitiet_id: chitiet_id
        }]
      }))
    }

  }

  useEffect(() => {
    fetchThucHien()
      .then(result => {
        checkExists(result.ky.data)
        setDapheduyet(result.ky.data.daduyet)
        setThucHien({
          ten_ky: result.ky.data.ten_dv,
          donvi_thuchien: result.donvi.data.ten_dv,
        })
      })
  }, [])


  const checkExists = ky => {
    setPeriod(ky)
    return ky.dadanhgia && ky.dadanhgia.find(x => x === parseInt(params.donvi));
  }

  const checkDapheduyet = () => {
    return dapheduyet.find(x => x === params.donvi);
  }

  useEffect(() => {
    fetchKetQua()
      .then(responseRate => {
        setKetQua(responseRate.data)
        return responseRate;
      })
      .then(responseRate => {
        fetchCategories()
          .then(result => {
            result.period.map((period, periodI) => {
              if (period.id === parseInt(params.ky)) {
                setSent(result.period[periodI])
              }
            })
            result.categories.map(item => {
              item.Childs = []
              result.categoryDetails.map(childItem => {
                childItem.Contents = []
                if (item.id == childItem.id_danhmuc)
                  item.Childs.push(childItem)
                result.detailContents.map((content, contentKey) => {
                  content.diemdonvi = responseRate.data[contentKey].diemdonvi
                  content.photo = responseRate.data[contentKey].photo
                  if (childItem.id == content.id_chitiettieuchi)
                    childItem.Contents.push(content)
                })
              })
            })
            return result
          })
          .then(result => {
            setCategories(result)
          })
          .catch(() => {
            alert('Chưa có đánh giá')
            navigate(-1)
          })
      })
  }, [])

  const diem = content => {
    return ketqua.find(x => x.chitietnoidung_id == content) ? ketqua.find(x => x.chitietnoidung_id == content).diemtothamdinh : ''
  }

  const handleSubmit = () => {

    if (form.noidung.length < categories.detailContents.length) {
      alert(`Vui lòng kiểm tra tiêu chí thẩm định: ${form.noidung.length}/${categories.detailContents.length} tiêu chí`)
      return false;
    }

    setBtnClick(true);

    axios({
      url: `/api/thamdinh/${params.ky}/${params.donvi}`,
      method: 'PUT',
      data: form
    })
      .then(res => {
        if (res.data.success) {
          alert('Cảm ơn bạn đã gửi điểm thẩm định cho ' + thuchien.donvi_thuchien)
          window.location.href = ''
        }
      })

  }

  const returnPhieu = () => {
    alert('Đã gửi trả phiếu cho đơn vị.');
    navigate(-1)
  }

  if (!categories.detailContents)
    return <Loading />;

  return (
    <div className="container section-main py-3">
      <div className="row">
        <div className="col-12">
          <p className="fst-italic">Bảng tự đánh giá kỳ tháng {period.thang} năm {period.nam} của {thuchien.donvi_thuchien}</p>
          <ul className="list-inline d-flex justify-content-center">
            <li className="list-inline-item mr-5">{checkDadanhgia() && <span className="text-success">Đơn vị đã đánh giá</span>}</li>
            <li className="list-inline-item mr-5">{checkThamdinh() ? <span className="text-success">Đã thẩm định</span> : <span className="text-danger">Chưa thẩm định</span>}</li>
          </ul>

          <div className="my-3 text-center">
            {!checkThamdinh() ?
              <>
                {form.noidung.length === 50 && <div className="list-inline-item"><button className="btn btn-success" onClick={() => handleSubmit()}>Xác nhận Thẩm định</button></div>}
                <div className="list-inline-item"><button className="btn btn-danger" onClick={() => returnPhieu()}>Không phê duyệt</button></div>
              </> :
              <div className="list-inline-item"><button className="btn btn-success" disabled onClick={() => handleSubmit()}>Đã Thẩm định</button></div>
            }

            <div className="list-inline-item"><button className="btn btn-secondary" onClick={() => navigate(-1)} >Quay lại</button></div>
          </div>

          {btnClick &&
            <div className="my-3">
              Đang gửi đánh giá, vui lòng đợi...
            </div>
          }

          {categories.categories.map((item, i) => (
            <div className="card mb-3" key={i}>
              <h6 className="card-header fw-bold bg-secondary text-white">{item.sothutu}. {item.ten_dm}</h6>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="2%">TT</th>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="15%">Đối tượng đánh giá</th>
                      <th valign="middle" rowSpan={1} className="text-center fw-bold" width="53%" colSpan={i <= 1 ? 2 : undefined}>Nội dung tiêu chí</th>
                      <th valign="middle" className="text-center fw-bold" width="10%">Điểm chỉ tiêu</th>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="10%">Điểm đơn vị chấm</th>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="10%">Điểm thẩm định</th>
                    </tr>
                    <tr>
                      <th valign="middle" className="text-center fw-bold" width="10%" colSpan={i <= 1 ? 2 : undefined}>Tổng điểm các tiêu chí</th>
                      <th valign="middle" className="text-center fw-bold" width="10%">20</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.Childs.map(childItem => (
                      childItem.Contents.map((content, j) => (
                        <tr key={content.id}>
                          <td className="text-center" valign="middle">{content.id}</td>
                          {j === 0 && <td valign="middle" rowSpan={childItem.Contents.length}>{childItem.name}</td>}
                          <td>

                            {content.noidung}

                          </td>
                          {i <= 1 &&
                            <td>
                              {content.photo ? <a href={content.photo} target="_blank"><img src={content.photo} width={100} /></a> :
                                <div style={{ width: 100 }} />}
                            </td>
                          }
                          <td align="center" valign="middle">2</td>
                          <td align="center" valign="middle">
                            {content.diemdonvi}
                          </td>
                          <td align="center" valign="middle">
                            {!checkThamdinh() ? <input type="number"
                              name="diemthamdinh"
                              className={`form-control fw-bold text-center text-primary rate-${content.id}`}
                              onChange={(e) => handleSetForm(e, content.id, childItem.id_danhmuc, childItem.id)}
                            /> : diem(content.id)}
                          </td>
                        </tr>
                      ))))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Thamdinh;