import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../partials/Loading';
import _ from 'lodash';

const Show = () => {
  const navinate = useNavigate()
  const params = useParams()
  const [phieu, setPhieu] = useState(false)
  const [categories, setCategories] = useState([])

  const fetchPhieu = async () => {
    const categories = await axios.get('/api/tieuchi_category')
    const phieu = await axios.get(`/api/phieu/${params.id}`)
    return {
      categories: categories.data,
      phieu: phieu.data
    }
  }

  useEffect(() => {
    fetchPhieu()
      .then(result => {
        setPhieu(result.phieu)
        setCategories(result.categories)
      })
  }, [])

  if (!phieu) return <Loading />

  const getPoint = noidung_id => {
    const point = _.find(phieu.kiemtra, el => {
      return el.noidung_id == noidung_id
    })
    return point
  }

  return (
    <div className="container section-main py-3">
      <ul className="list-inline">
        <li className="list-inline-item"><button className="btn btn-secondary" onClick={() => navinate(-1)}>Quay lại</button></li>
        <li className="list-inline-item"><a className="btn btn-primary" href={`/print/kiemtra/${params.id}`} target="_blank">In Phụ lục</a></li>
      </ul>            
      <h3 className="my-3">{phieu.title}</h3>
      {categories.map((category, categoryIndex) => (
        <div className="card mb-3" key={categoryIndex}>
          <div className="card-header bg-secondary text-white">{category.sothutu}. {category.ten_dm}</div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center" valign="middle" rowSpan={2}>TT</th>
                  <th className="text-center" valign="middle" rowSpan={2}>Đối tượng đánh giá</th>
                  <th className="text-center" valign="middle" rowSpan={1} colSpan={categoryIndex <= 1 ? 2 : undefined}>Nội dung tiêu chí</th>
                  <th className="text-center" valign="middle" rowSpan={1}>Điểm chỉ tiêu</th>
                  <th className="text-center" valign="middle" rowSpan={2}>Đánh giá</th>
                </tr>
                <tr>
                  <th className="text-center" valign="middle" colSpan={categoryIndex <= 1 ? 2 : undefined}>Tổng điểm các tiêu chí</th>
                  <th className="text-center" valign="middle">20</th>
                </tr>
              </thead>
              <tbody>
                {category.tieuchi.map(tieuchi => (
                  tieuchi.noidung.map((noidung, noidungIndex) => (
                    <tr key={noidungIndex}>
                      <td className="text-center" valign="middle" width={40}>{noidung.id}</td>
                      {noidungIndex === 0 && <td width="22%" valign="middle" rowSpan={tieuchi.noidung.length}>{tieuchi.name}</td>}
                      <td>
                        {noidung.noidung}
                      </td>
                      {
                        categoryIndex <= 1 &&
                        <td>
                          {phieu.kiemtra[noidungIndex] ?
                            <a href={getPoint(noidung.id).photo} target="_blank">
                              <img src={getPoint(noidung.id).photo} width={100} /></a> :
                            <div style={{ width: 100 }} />
                          }
                        </td>
                      }
                      <td className="text-center" valign="middle" width="10%">2</td>
                      <td className="text-center" valign="middle" width="10%">
                        {getPoint(noidung.id).diem_danhgia}
                      </td>
                    </tr>
                  ))
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Show;