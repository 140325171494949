import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navbar from './partials/Navbar';
import Home from './pages/Home';
import Donvidanhgia from './pages/danhgia/Donvidanhgia';
import List from './pages/List';
import Footer from './partials/Footer';
import Pheduyet from './pages/danhgia/Pheduyet';
import Thamdinh from './pages/danhgia/Thamdinh';
import axios from 'axios';
import Show from './pages/phieu/Show';
import Ketqua_hoatdong from './pages/danhgia/baocao/Ketqua_hoatdong';
import DanhGiaHoatDongATVSV from './pages/danhgia/ChamDiem/DanhGiaHoatDongATVSV';

const Layout = () => {

  useEffect(() => {
    fetchMyInfo()
      .then(response => {
        if (response.data.role_id != sessionStorage.getItem('role_id')) {
          sessionStorage.removeItem('logged');
          sessionStorage.removeItem('user_id');
          sessionStorage.removeItem('donvi_id');
          sessionStorage.removeItem('role_id');
          sessionStorage.removeItem('username');
          sessionStorage.removeItem('ten_donvi');
          sessionStorage.removeItem('fullname');
          window.location.href = '/'
        }
      })
  }, [])

  const fetchMyInfo = () => {
    return axios.get(`/api/user/${sessionStorage.getItem('user_id')}`)
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/danhgia/donvi/:ky" element={<Donvidanhgia />} />
        <Route path="/pheduyet/:ky/:donvi" element={<Pheduyet />} />
        <Route path="/thamdinh/:ky/:donvi" element={<Thamdinh />} />
        <Route path="/danhgia" element={<List />} />
        <Route path="/danhgia/baocao_ketquahoatdong_thang" element={<Ketqua_hoatdong />} />
        <Route path="/chamdiem/baocao_ketquahoatdong_thang/:donvi/:period_id" element={<DanhGiaHoatDongATVSV />} />
        <Route path="/kiemtra" element={<List />} />
        <Route path="/phieu/:id" element={<Show />} />
        <Route path="/print/kiemtra/:id" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Layout;