import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';

const Navbar = () => {
  const handleLogout = () => {
    if (window.confirm('Bạn có muốn thoát tài khoản?')) {
      sessionStorage.removeItem('logged');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('donvi_id');
      sessionStorage.removeItem('role_id');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('ten_donvi');
      sessionStorage.removeItem('fullname');

      window.location.href = '/'
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={logo} /> ATVSLĐ</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">Trang chủ</Link>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#">Hướng dẫn</a>
            </li> */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Đánh giá ATVSLĐ
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {parseInt(sessionStorage.getItem('role_id')) === 1 && <li><Link className="dropdown-item" to="/danhgia">1. Đơn vị tự đánh giá hàng tháng</Link></li>}
                {parseInt(sessionStorage.getItem('role_id')) === 1 && <li><Link className="dropdown-item" to="/danhgia/baocao_ketquahoatdong_thang">2. Báo cáo kết quả hoạt động ATVSV</Link></li>}
                {parseInt(sessionStorage.getItem('role_id')) === 2 && <li><Link className="dropdown-item" to="/danhgia">Kiểm tra đánh giá</Link></li>}
                {parseInt(sessionStorage.getItem('role_id')) === 3 && <li><Link className="dropdown-item" to="/danhgia">Thẩm định đánh giá ATVSLĐ</Link></li>}
                {parseInt(sessionStorage.getItem('role_id')) === 3 && <li><Link className="dropdown-item" to="/kiemtra">Kiểm tra ATVSLĐ</Link></li>}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                {sessionStorage.getItem('fullname')}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#" onClick={handleLogout}>Thoát tài khoản</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;