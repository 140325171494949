import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Listpheduyet = ({ kys, thisMonth, thisYear, redMonth }) => {
  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState(false)

  const handleDepartmentChange = e => {
    setDepartment(e.target.value)
  }

  useEffect(() => {
    axios.get('/api/donvi')
      .then(result => {
        setDepartments(result.data)
      })

  }, [])

  return (
    <div className="container section-main py-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="mb-3">
            <p className="fst-italic">Chọn đơn vị để phê duyệt tự đánh giá</p>
            <select name="donvi" className="form-select" defaultValue="Chọn đơn vị" onChange={handleDepartmentChange}>
              <option disabled>Chọn đơn vị</option>
              {departments.map((item, i) => item.ten_dv.match('TTVT|Trung tâm') && <option key={i} value={item.id}>{item.ten_dv}</option>)}
            </select>
          </div>
          {department && <>
            <p className="fst-italic">Chọn kỳ đánh giá</p>
            <div className="card mb-3">
              <h6 className="card-header fw-bold bg-secondary text-white">Danh sách kỳ năm 2022</h6>
              <div className="card-body">
                <div className="list-group list-group-flush">
                  {kys.map((item, i) => <Link to={`/pheduyet/${item.id}/${department}`} className={`list-group-item list-group-item-action ${redMonth(thisMonth, thisYear, item)}`} key={item.id}>
                    {i + 1}. Đánh giá, chấm điểm tháng {item.thang} năm {item.nam}
                  </Link>)}
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default Listpheduyet;