import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const Listkiemtra = () => {
  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState(false)
  const [form, setForm] = useState(false)
  const [categories, setCategories] = useState([])
  const [images, setImages] = useState([])
  const [phieus, setPhieus] = useState([])

  const [btnClick, setBtnClick] = useState(false);

  const date = new Date()

  const handleDepartmentChange = e => {
    const dI = _.findIndex(departments, de => de.id === parseInt(e.target.value))
    setDepartment(department => (department, { id: e.target.value, ten_donvi: departments[dI].ten_dv }))
  }


  const handleKhoitaoBtnClick = () => {
    setForm({
      title: `Kiểm tra ATVSLĐ tháng ${date.getMonth() + 1} - ${department.ten_donvi}`,
      donvi_id: department.id,
      noidung: [],
      user_id: sessionStorage.getItem('user_id')
    })
  }

  useEffect(() => {
    fetchPhieus()
      .then(result => setPhieus(result.data))
  }, [])

  const fetchPhieus = () => {
    return axios.get('/api/phieu');
  }

  const handleSetForm = (e, id) => {
    if (e.target.value > 2) {
      e.target.value = 2
    }

    if (e.target.value < 1) {
      e.target.value = 1
    }

    const newArray = form.noidung
    const exist = _.findIndex(newArray, { noidung_id: id })

    if (exist >= 0) {
      newArray[exist].so_diem = e.target.value
      setForm(form => ({ ...form, noidung: newArray }))
    } else
      setForm(form => ({
        ...form, noidung: [...form.noidung, {
          noidung_id: id,
          so_diem: e.target.value,
        }]
      }))
  }

  const handleUpload = (e, id) => {

    const image = e.target.files[0]
    const blob = URL.createObjectURL(image)
    const test = document.getElementsByClassName(`test-${id}`)

    test[0].innerHTML = `<img src="${blob}" width="100" height="auto" />`

    const contentId = _.findIndex(form.noidung, el => el.noidung_id === id)

    const reader = new FileReader();
    reader.readAsDataURL(image)

    reader.onloadend = () => {
      form.noidung[contentId].image = reader.result
    }

    setForm(form)

  }

  const canUploadImage = (content_id) => {
    const exist = _.findIndex(form.noidung, { noidung_id: content_id })
    return form.noidung[exist] && form.noidung[exist].noidung_id === content_id
  }

  const handleSubmit = () => {
    if (form.noidung.length < 50) {
      alert(`Vui lòng kiểm tra tiêu chí thẩm định: ${form.noidung.length}/${categories.length} tiêu chí`)
      return false;
    }
    setBtnClick(true);
    axios({
      url: '/api/phieu',
      method: 'POST',
      data: form
    })
      .then(res => {
        if (res.data.success) {
          alert('Gửi đánh giá thành công!')
          window.location.href = ''
        }
      })
  }

  const uploadImage = () => {
    return new Promise(resolve => {
      images.map(item => {
        const formData = new FormData;
        formData.append('id', item.id);
        formData.append('image', item.image);

        axios({
          method: 'POST',
          url: '/api/upload',
          data: formData,
          // headers: {
          //   'Content-Type': 'multipart/form-data; boundary='
          // }
        })
          .then(response => {
            const exist = _.findIndex(form.noidung, { noidung_id: item.id })
            form.noidung[exist].image = response.data.url
            setForm(form => ({ ...form, noidung: form.noidung }))
          })
      })
      resolve(form)
    })
  }

  useEffect(() => {
    axios.get('/api/donvi')
      .then(result => {
        setDepartments(result.data)
      })
  }, [])

  useEffect(() => {
    if (form) {
      axios.get('/api/tieuchi_category')
        .then(result => {
          setCategories(result.data)
        })
    }
  }, [form])

  if (form)
    return (
      <div className="container section-main py-3">
        <h3 className="my-3">{form.title}</h3>
        <div className="my-3">
          <ul className="list-inline">
            {form.noidung.length === 50 &&

              <li className="list-inline-item">
                <button className="btn btn-success" type="submit" onClick={handleSubmit} disabled={btnClick}>Hoàn tất kiểm tra</button>
              </li>

            }
            <li className="list-inline-item">
              <button className="btn btn-secondary" onClick={() => setForm(false)}>Quay lại</button>
            </li>
          </ul>
          {btnClick &&
            <div className="my-3">
              Đang gửi đánh giá, vui lòng đợi...
            </div>
          }
        </div>

        {categories.map((category, categoryIndex) => (
          <div className="card mb-3" key={categoryIndex}>
            <div className="card-header bg-secondary text-white">{category.sothutu}. {category.ten_dm}</div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center" valign="middle" rowSpan={2}>TT</th>
                    <th className="text-center" valign="middle" rowSpan={2}>Đối tượng đánh giá</th>
                    <th className="text-center" valign="middle" rowSpan={1} colSpan={categoryIndex <= 1 ? 2 : undefined}>Nội dung tiêu chí</th>
                    <th className="text-center" valign="middle" rowSpan={1}>Điểm chỉ tiêu</th>
                    <th className="text-center" valign="middle" rowSpan={2}>Đánh giá</th>
                  </tr>
                  <tr>
                    <th className="text-center" valign="middle" colSpan={categoryIndex <= 1 ? 2 : undefined}>Tổng điểm các tiêu chí</th>
                    <th className="text-center" valign="middle">20</th>
                  </tr>
                </thead>
                <tbody>
                  {category.tieuchi.map(tieuchi => (
                    tieuchi.noidung.map((noidung, noidungIndex) => (
                      <tr key={noidungIndex}>
                        <td className="text-center" valign="middle" width={40}>{noidung.id}</td>
                        {noidungIndex === 0 && <td width="22%" valign="middle" rowSpan={tieuchi.noidung.length}>{tieuchi.name}</td>}
                        <td>{noidung.noidung}</td>
                        {categoryIndex <= 1 && <td width="10%" className="text-center" valign="middle">
                          {canUploadImage(noidung.id) ?
                            <label type="button" className="btn btn-dark btn-sm">
                              <div className={`test-${noidung.id}`}></div>
                              <input type="file" style={{ display: 'none' }} accept="image/*" onChange={e => handleUpload(e, noidung.id)}></input>
                              Chụp Ảnh
                            </label>
                            : <div style={{ width: 100 }} />
                          }
                        </td>}
                        <td className="text-center" valign="middle" width="10%">2</td>
                        <td className="text-center" valign="middle" width="10%">
                          <input type="number" name="" className="form-control fw-bold text-center text-primary" onChange={(e) => handleSetForm(e, noidung.id)} />
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    )

  return (
    <div className="container section-main py-3">
      <p className="fst-italic">Chức năng dành cho người thẩm định ATVSLĐ. Chọn đơn vị để kiểm tra &amp; đánh giá</p>
      <div className="row g-2 mb-3">
        <div className="col-lg-8">
          <select name="donvi" className="form-select" defaultValue="Chọn đơn vị" onChange={handleDepartmentChange}>
            <option disabled>Chọn đơn vị</option>
            {departments.map((item, i) => item.ten_dv.match('TTVT|Trung tâm') && <option key={i} value={item.id}>{item.ten_dv}</option>)}
          </select>
        </div>
        <div className="col">
          {department.id && <button className="btn btn-primary" onClick={handleKhoitaoBtnClick}>Khởi tạo kiểm tra</button>}
        </div>
      </div>
      <div className="card">
        <div className="card-header">Danh sách phiếu đã gửi</div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th width={50} className="text-center">STT</th>
                <th>Tên phiếu</th>
                <th>Đánh giá cho</th>
                <th>Ngày gửi</th>
                <th>Người tạo</th>
              </tr>
            </thead>
            <tbody>
              {phieus.map((item, i) => (
                <tr key={item.id}>
                  <td className="text-center">{i + 1}</td>
                  <td>
                    <Link to={`/phieu/${item.id}`}>{item.title}</Link>
                  </td>
                  <td>{item.donvi.ten_dv}</td>
                  <td>{item.khoitao}</td>
                  <td>{item.user.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Listkiemtra;