import React from 'react';
import { Link } from 'react-router-dom';
import WordIcon from '../../pages/danhgia/word.png';
import DeleteIcon from '../../pages/danhgia/delete.png';
import axios from 'axios';

const imgBW = { filter: 'grayscale(100%)' }

const removeBaocao = id => {

  if (window.confirm('Bạn có muốn xóa báo cáo này?'))
    axios.delete('/api/hoatdong_atvs/' + id)
      .then(response => {

        if (response.data.success) window.location.href = ''
      })
}

const removeChamdiem = id => {

  if (window.confirm('Bạn có muốn xóa đánh giá này?'))
    axios.delete('/api/chamdiem_atvs/' + id)
      .then(response => {

        if (response.data.success) window.location.href = ''
      })
}

export const TuDanhGia_Admin = props => {

  const { ct } = props

  return (
    <Link to={`/${sessionStorage.getItem('role_id') == 3 ? 'thamdinh' : 'pheduyet'}/${ct.id_ky}/${ct.donvi_id}`} >{ct.sum_diemdonvi}</Link>
  );
};
export const TuDanhGia_User = props => {

  const { ct } = props

  return ct.sum_diemdonvi
};

export const BaoCaoHoatDong_Admin = props => {

  const { ct } = props

  return ct.baocaohoatdong &&
    <>
      <a
        title="Tải về"
        href={`/download/baocao_hoatdong_atvs_thang/${ct.donvi_id}/${ct.id_ky}`}
      >
        <img src={WordIcon} width={18} />
      </a> <img src={DeleteIcon} width={18} onClick={() => removeBaocao(ct.baocaohoatdong)} style={{ cursor: 'pointer' }} />
    </>
};
export const BaoCaoHoatDong_User = props => {

  const { ct } = props

  return ct.baocaohoatdong && <img src={WordIcon} width={18} style={imgBW} />
};

export const ChamDiemHoatDong_Admin = props => {

  const { ct } = props

  return ct.chamdiemhoatdong ?
    <>
      <a
        title="Tải về"
        href={`/download/chamdiem_hoatdong_atvs_thang/${ct.donvi_id}/${ct.id_ky}`}
      >
        <img src={WordIcon} width={18} /></a> {sessionStorage.getItem('role_id') == 3 && <img src={DeleteIcon} width={18} onClick={() => removeChamdiem(ct.chamdiemhoatdong)} style={{ cursor: 'pointer' }} />}
    </> :
    (sessionStorage.getItem('role_id') == 3 && <Link to={`/chamdiem/baocao_ketquahoatdong_thang/${ct.donvi_id}/${ct.id_ky}`}>Đánh giá</Link>)
};
export const ChamDiemHoatDong_User = props => {

  const { ct } = props

  return ct.chamdiemhoatdong && <img src={WordIcon} width={18} style={imgBW} />
};

export default TuDanhGia_Admin;