import Layout from './components/Layout';
import Login from './components/pages/Login';

function App() {

  if (sessionStorage.getItem('logged')) {
    return <Layout />
  }
  // return <Layout />

  return (
    <Login />
  );
}

export default App;
