import React from 'react';
import loadingGif from './loading.gif';

const Loading = () => {
  return (
    <div className="container section-main py-3 text-center">
      <img src={loadingGif} width={70} />
    </div>
  );
};

export default Loading;