import React from 'react';

const Footer = () => {
  return (
    <div className="text-center mt-3">
      <p className="text-light copy-right">
        Hệ thống chấm điểm An toàn vệ sinh lao động - VNPT Sơn La<br />
        Đơn vị thực hiện: Trung tâm Công nghệ thông tin
      </p>
    </div>
  );
};

export default Footer;