import axios from 'axios';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../partials/Loading';

const Donvidanhgia = () => {

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [params])

  const [categories, setCategories] = useState([])
  const [sent, setSent] = useState(false)
  const [ketqua, setKetQua] = useState([])
  const [form, setForm] = useState({
    noidung: [],
    donvi_id: sessionStorage.getItem('donvi_id'),
    user_id: sessionStorage.getItem('user_id'),
    ky_id: params.ky,
  })

  const [btnClick, setBtnClick] = useState(false);


  const fetchCategories = async () => {
    const categories = await axios.get('/api/dm_tieuchi')
    const period = await axios.get('/api/period')
    const categoryDetails = await axios.get('/api/dm_chitiettieuchi')
    const detailContents = await axios.get('/api/dm_noidung')

    return {
      categories: categories.data,
      categoryDetails: categoryDetails.data,
      detailContents: detailContents.data,
      period: period.data
    }
  }

  useEffect(() => {
    fetchCategories()
      .then(result => {
        result.period.map((period, periodI) => {
          if (period.id === parseInt(params.ky)) {
            setSent(result.period[periodI])
          }
        })
        result.categories.map(item => {
          item.Childs = []
          result.categoryDetails.map(childItem => {
            childItem.Contents = []
            if (item.id == childItem.id_danhmuc)
              item.Childs.push(childItem)
            result.detailContents.map(content => {
              if (childItem.id == content.id_chitiettieuchi)
                childItem.Contents.push(content)
            })
          })
        })
        return result
      })
      .then(result => {
        setCategories(result)
      })
  }, [])

  const checkDadanhgia = () => {
    return sent.dadanhgia && sent.dadanhgia.find(x => x === sessionStorage.getItem('donvi_id'))
  }

  const checkPheduyet = () => {
    return sent.daduyet && sent.daduyet.find(x => x === sessionStorage.getItem('donvi_id'))
  }

  const checkThamdinh = () => {
    return sent.dathamdinh && sent.dathamdinh.find(x => x === sessionStorage.getItem('donvi_id'))
  }

  useEffect(() => {
    if (checkDadanhgia())
      fetchKetQua()
        .then(response => {
          setKetQua(response.data)
        })
  }, [sent])

  const fetchKetQua = () => {
    return axios.get('/api/ketqua/filter', {
      params: {
        donvi_id: sessionStorage.getItem('donvi_id'),
        ky_id: params.ky,
      }
    })
  }

  const handleSetForm = (e, id, danhmuc_id, chitiet_id) => {

    if (e.target.value > 2) {
      e.target.value = 2
    }

    if (e.target.value < 1) {
      e.target.value = 1
    }

    const newArray = form.noidung
    const exist = _.findIndex(newArray, { noidung_id: id })

    if (exist >= 0) {
      newArray[exist].so_diem = e.target.value
      setForm(form => ({ ...form, noidung: newArray }))
    } else {
      setForm(form => ({
        ...form, noidung: [...form.noidung, {
          noidung_id: id,
          so_diem: e.target.value,
          danhmuc_id: danhmuc_id,
          chitiet_id: chitiet_id
        }]
      }))
    }

  }

  const handleSubmit = () => {

    if (form.noidung.length < categories.detailContents.length) {
      alert(`Bạn chưa nhập đủ tiêu chí: ${form.noidung.length}/${categories.detailContents.length}`)
      return false;
    }

    setBtnClick(true);

    const formData = new FormData();

    formData.append('noidung', form.noidung)
    formData.append('donvi_id', form.donvi_id)
    formData.append('user_id', form.user_id)

    axios({
      url: '/api/ketqua',
      method: 'POST',
      data: form,
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // }
    })
      .then(res => {
        if (res.data.success) {
          alert('Cảm ơn bạn đã gửi đánh giá')
          window.location.href = ''
        }
      })

  }


  const canUploadImage = (book_id, content_id) => {
    const exist = _.findIndex(form.noidung, { noidung_id: content_id })
    return form.noidung[exist] && form.noidung[exist].noidung_id === content_id && book_id <= 2
  }

  const handleUpload = (e, id) => {
    const image = e.target.files[0]
    const blob = URL.createObjectURL(image)
    const test = document.getElementsByClassName(`test-${id}`)

    test[0].innerHTML = `<img src="${blob}" width="100" height="auto" />`

    const contentId = _.findIndex(form.noidung, el => el.noidung_id === id)

    const reader = new FileReader();
    reader.readAsDataURL(image)

    reader.onloadend = () => {
      form.noidung[contentId].image = reader.result
    }


    setForm(form)

  }


  const diem = content => {
    return ketqua.find(x => x.chitietnoidung_id == content) ? ketqua.find(x => x.chitietnoidung_id == content).diemdonvi : ''
  }

  const anh = content => {
    return ketqua.find(x => x.chitietnoidung_id == content) ? ketqua.find(x => x.chitietnoidung_id == content).photo : ''
  }


  if (!categories.detailContents)
    return <Loading />;

  return (
    <div className="container section-main py-3">
      <div className="row">
        <div className="col-12">
          <button className="btn btn-secondary" onClick={() => navigate(-1)}>Quay lại</button>
          <div className="row my-3">
            <div className="col-sm-8">
              <div className="fst-italic">Bảng tự đánh giá của {sessionStorage.getItem('ten_donvi')}, người thực hiện {sessionStorage.getItem('fullname')}</div>
              <div className="fst-italic">Tổng điểm các tiêu chí: 100 điểm</div>
            </div>
            <div className="col-sm-4 text-end">
              <div>{checkDadanhgia() ? <span className="text-success">Đơn vị đã đánh giá</span> : <span className="text-danger">Đơn vị chưa đánh giá</span>}</div>
              <div>{checkThamdinh() ? <span className="text-success">Đã thẩm định</span> : <span className="text-danger">Chưa thẩm định</span>}</div>
            </div>
          </div>
          {form.noidung.length === 50 && <div className="my-3">
            <button className="btn btn-primary" type="submit" onClick={handleSubmit} disabled={btnClick}>Gửi bản tự đánh giá</button>
          </div>}

          {btnClick &&
            <div className="my-3">
              Đang gửi đánh giá, vui lòng đợi...
            </div>
          }

          {categories.categories.map((item, i) => (
            <div className="card mb-3" key={i}>
              <h6 className="card-header fw-bold bg-secondary text-white">{item.sothutu}. {item.ten_dm}</h6>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="2%">TT</th>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="15%">Đối tượng đánh giá</th>
                      <th valign="middle" rowSpan={1} className="text-center fw-bold" width="53%" colSpan={i <= 1 ? 2 : undefined}>Nội dung tiêu chí</th>
                      <th valign="middle" className="text-center fw-bold" width="10%">Điểm chỉ tiêu</th>
                      <th valign="middle" rowSpan={2} className="text-center fw-bold" width="10%">Điểm đơn vị chấm</th>
                    </tr>
                    <tr>
                      <th valign="middle" className="text-center fw-bold" width="10%" colSpan={i <= 1 ? 2 : undefined}>Tổng điểm các tiêu chí</th>
                      <th valign="middle" className="text-center fw-bold" width="10%">20</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.Childs.map(childItem => (
                      childItem.Contents.map((content, j) => (
                        <tr key={content.id}>
                          <td className="text-center" valign="middle">{content.id}</td>
                          {j === 0 && <td valign="middle" rowSpan={childItem.Contents.length}>{childItem.name}</td>}
                          <td>

                            {content.noidung}

                          </td>
                          {i <= 1 &&
                            <td width="10%" className="text-center" valign="middle">
                              {canUploadImage(item.id, content.id) && <div>
                                <label type="button" className="btn btn-dark btn-sm">
                                  <div className={`test-${content.id}`}></div>
                                  <input type="file" style={{ display: 'none' }} accept="image/*" onChange={e => handleUpload(e, content.id)}></input>
                                  Chụp Ảnh
                                </label>
                              </div>}

                              {checkDadanhgia() && anh(content.id) &&
                                <div>
                                  <a href={`${anh(content.id)}`} target="_blank"><img src={`${anh(content.id)}`} width={100} /></a>
                                </div>
                              }
                            </td>
                          }
                          <td align="center" valign="middle">2</td>
                          <td align="center" valign="middle">
                            {!checkDadanhgia() ? <input type="number"
                              name="donvicham"
                              className={`form-control fw-bold text-center text-primary rate-${content.id}`}
                              onChange={(e) => handleSetForm(e, content.id, childItem.id_danhmuc, childItem.id)}
                              defaultValue={diem(content.id)}
                            /> : diem(content.id)}
                          </td>
                        </tr>
                      ))))}
                  </tbody>
                </table>
              </div>              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Donvidanhgia;