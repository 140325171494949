import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../../../partials/Loading';
import Ketqua_hoatdong_show from './Ketqua_hoatdong_show';
import _ from 'lodash';
import Period from './Period';

const Ketqua_hoatdong = () => {

  const [period, setPeriod] = useState([])

  const [danhmuc, setDanhmuc] = useState({
    chitiet: []
  })
  const [danhmuc2, setDanhmuc2] = useState([])

  const [errors, setErr] = useState()

  const [form, setForm] = useState({
    user_id: sessionStorage.getItem('user_id'),
    donvi_id: sessionStorage.getItem('donvi_id'),
    period_id: false,
    todoi: null,
    chucdanh: null,
    tnld_chet: 0,
    tnld_nang: 0,
    tnld_nhe: 0,
  });

  const user = {
    ten_donvi: sessionStorage.getItem('ten_donvi'),
    fullname: sessionStorage.getItem('fullname'),
  }

  const fetchPeriod = async () => {

    return await axios.get('/api/period')
      .then(response => {

        if (form.period_id == 0) {
          const ped = _.find(response.data, function (p) {
            return p.current === 1;
          });

          setForm({ ...form, period_id: ped.id })

          setPeriod(response.data)
        }

        return form
      })
  }

  useEffect(() => {

    fetchPeriod()
      .then(form => {

        setDanhmuc([])
        setDanhmuc2([])
        setErr(false)

        if (form.period_id)
          axios.get('/api/hoatdong_atvs', { params: { id: form.period_id, donvi_id: form.donvi_id } })
            .then(res => {

              if (res.data.success) {

                setDanhmuc([])
                setDanhmuc2(res.data.danhmuc)
              } else {

                const arr = []

                res.data.map(item => {
                  arr.push({
                    name: item.name,
                    noidung_id: item.id,
                    noidung_cv: item.noidung_cv,
                    thoigian_th: null,
                    ketqua: null,
                    ghichu: null,
                  })
                })

                setDanhmuc2([])
                setDanhmuc(arr)
              }

            })
      })

  }, [form.period_id])

  const formChange = (e, id = null) => {

    if (id) {
      const index = danhmuc.findIndex(item => {

        return item.noidung_id == id;
      })

      setDanhmuc(danhmuc => {

        if (e.target.id == 'thoigian_th') danhmuc[index].thoigian_th = e.target.value
        if (e.target.id == 'ketqua') danhmuc[index].ketqua = e.target.value
        if (e.target.id == 'ghichu') danhmuc[index].ghichu = e.target.value

        return [...danhmuc]
      })


    } else {

      setForm(form => {

        if (e.target.name == 'tnld_chet') form.tnld_chet = e.target.value
        if (e.target.name == 'tnld_nang') form.tnld_nang = e.target.value
        if (e.target.name == 'tnld_nhe') form.tnld_nhe = e.target.value

        if (e.target.name == 'todoi') form.todoi = e.target.value
        if (e.target.name == 'chucdanh') form.chucdanh = e.target.value

        return { ...form }
      })
    }

  }

  const handleSubmit = () => {

    if (window.confirm('Xác nhận gửi báo cáo?')) {
      setErr(false)

      axios.post('/api/hoatdong_atvs', {

        ...form, danhmuc
      })
        .then(response => {

          if (!response.data.success) {

            setErr(response.data.msg)
          } else {

            window.location.href = ''
          }
        })
    }

  }

  if (!danhmuc.length && !danhmuc2.chitiet) return <Loading />

  if (danhmuc2.chitiet && danhmuc2.chitiet.length) return <Ketqua_hoatdong_show danhmuc={danhmuc2} form={form} setForm={setForm} period={period} />

  return (
    <div className="container section-main py-3">

      <Period form={form} setForm={setForm} period={period} />

      <div className="card shadow-sm">
        <div className="card-header fw-bold bg-secondary text-white">
          Báo cáo hoạt động của An toàn, vệ sinh viên hàng tháng
        </div>
        <div className="card-body">
          <p className="mb-1 fw-bold">Thông tin người báo cáo:</p>
          <table>
            <tbody>
              <tr>
                <td width={260}>- Họ và tên ATVS viên:</td>
                <td className="fw-bold">{user.fullname}</td>
              </tr>
              <tr>
                <td width={260}>- Đơn vị:</td>
                <td className="fw-bold">{user.ten_donvi}</td>
              </tr>
              <tr>
                <td>- Chức danh:</td>
                <td><input type="text" name="chucdanh" className="form-control" onChange={formChange} /></td>
              </tr>
              <tr>
                <td>- Thuộc nhóm/tổ/đội:</td>
                <td><input type="text" name="todoi" className="form-control" onChange={formChange} /></td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ chết người:</td>
                <td><input type="number" name="tnld_chet" defaultValue={0} min={0} className="form-control form-control-sm" style={{ width: 50 }} onChange={formChange} /></td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ nặng:</td>
                <td><input type="number" name="tnld_nang" defaultValue={0} min={0} className="form-control form-control-sm" style={{ width: 50 }} onChange={formChange} /></td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ nhẹ:</td>
                <td><input type="number" name="tnld_nhe" defaultValue={0} min={0} className="form-control form-control-sm" style={{ width: 50 }} onChange={formChange} /></td>
              </tr>
            </tbody>
          </table>
          <div className="row mb-2 row">
            <div className="col-6 align-self-end"><p className="fw-bold">Nội dung báo cáo:</p></div>
            <div className="col-6 text-end"><button className="btn btn-success" onClick={handleSubmit}>Gửi báo cáo</button></div>
          </div>
          {errors && <div className="alert alert-danger d-flex align-items-center mt-2" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              Lỗi gửi báo cáo. Các trường thông tin trống vui lòng nhập Không có.
            </div>
          </div>}
          <table width="100%" border="1" className="table table-striped">
            <thead>
              <tr className="bg-secondary text-white">
                <th>STT</th>
                <th>Nội dung công việc</th>
                <th>Thời gian thực hiện <span className="text-danger">(*)</span></th>
                <th>Kết quả <span className="text-danger">(*)</span></th>
                <th>Ghi chú (nếu có)</th>
              </tr>
            </thead>
            <tbody>
              {danhmuc.map(item => <tr key={item.noidung_id}>
                <td valign="middle" className="text-center">{item.name}</td>
                <td valign="middle" width="30%">{item.noidung_cv}</td>
                <td valign="middle" width="23.3%"><textarea className="form-control" id="thoigian_th" role="textbox" onChange={e => formChange(e, item.noidung_id)}></textarea></td>
                <td valign="middle" width="23.3%"><textarea className="form-control" id="ketqua" role="textbox" onChange={e => formChange(e, item.noidung_id)}></textarea></td>
                <td valign="middle" width="23.3%"><textarea className="form-control" id="ghichu" role="textbox" onChange={e => formChange(e, item.noidung_id)}></textarea></td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Ketqua_hoatdong;