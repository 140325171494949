import React  from 'react';
import { Link } from 'react-router-dom';

const Listdonvi = props => {

  const { kys, thisMonth, thisYear, redMonth, years, selectedYear, setSelectedYear } = props;

  return (
    <div className="container section-main py-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <p className="fst-italic">Chọn kỳ đánh giá {sessionStorage.getItem('ten_donvi')}</p>
          {years.length ? <div className="card mb-3">
            <h6 className="card-header fw-bold bg-secondary text-white">Danh sách kỳ năm <select defaultValue={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
              {years.map((item, i) => <option key={i} value={item.nam}>{item.nam}</option>)}
            </select></h6>
            <div className="card-body">
              <div className="list-group list-group-flush">
                {kys.map((item, i) => {

                  if (item.nam == selectedYear)
                    return (
                      <Link to={`/danhgia/donvi/${item.id}`} className={`list-group-item list-group-item-action ${redMonth(thisMonth, thisYear, item)}`} key={item.id}>
                        Tự đánh giá, chấm điểm tháng {item.thang} năm {item.nam}
                      </Link>
                    )

                })}
              </div>
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  );
};

export default Listdonvi;