import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import background from './login/background.jpg';
import $ from 'jquery';
import _ from 'lodash';

const Login = () => {

  const passwordI = useRef(false)

  useEffect(() => {
    document.body.style.backgroundColor = '#0060aa'
    document.body.style.backgroundImage = 'url(' + background + ')'
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundSize = 'contain'
    document.body.style.backgroundSize = '100vw 100vh'
    document.body.style.backgroundPosition = 'middle'
  });

  const [errs, setErr] = useState(false)
  const [form, setForm] = useState([])

  useEffect(() => {
    if (passwordI.current.value == '')
      setForm(form => ({ ...form, password: '' }))
  }, [passwordI])

  const handleSubmit = e => {
    axios.post('https://login.vnptsonla.com/api/ldap_login', {
      username: form.username && form.username + '@vnpt.vn',
      password: form.password
    })
      .then(res => {
        if (res.data.errors) {
          setErr(res.data)
        }
        else if (res.data.login === 'fail') {
          setErr({
            errors: [
              'Đăng nhập sai'
            ]
          })
          passwordI.current.value = ''
        }
        else if (res.data.login === 'success') {

          setErr(false)

          $('.login-success').html(`<div class="row mb-2">
                  <div class="col-7 offset-3">
                    <div class="text-success">Success!!</div>
                  </div>
                </div>`);


          axios.post('/api/login', {
            username: form.username
          })
            .then(res => {

              if (res.data.user_info.username === form.username) {
                sessionStorage.setItem('logged', true)
                sessionStorage.setItem('user_id', res.data.user_info.user_id)
                sessionStorage.setItem('donvi_id', res.data.user_info.donvi_id)
                sessionStorage.setItem('role_id', res.data.user_info.role_id)
                sessionStorage.setItem('username', res.data.user_info.username)
                sessionStorage.setItem('ten_donvi', res.data.user_info.ten_donvi)
                sessionStorage.setItem('fullname', res.data.user_info.fullname)
              }

              if (sessionStorage.getItem('logged')) {
                window.location.href = '/'
              }

            })
        }
      })
  }

  const handleChange = e => {
    setForm({
      ...form, [e.target.name]: e.target.value
    })
  }

  const hotroBtn = () => {
    alert('Email: bachpd.sla@vnpt.vn')
  }

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center" style={{ height: '80vh' }}>
        <div className="col-md-12 col-lg-7">
          <div className="card shadow border-0">
            <div className="card-header text-uppercase text-light" style={{ backgroundColor: '#0060aa' }}>
              Hệ thống chấm điểm An toàn vệ sinh lao động - VNPT Sơn La
            </div>
            <div className="card-body">
              <form onSubmit={(e) => { e.preventDefault() }}>
                <h5 className="card-title mb-3">Đăng nhập hệ thống</h5>
                <div className="row mb-2 align-items-center">
                  <label className="col-3">Tài khoản</label>
                  <div className="col-7">
                    <input type="text" className="form-control" name="username" onChange={handleChange} autoFocus autoComplete={false} />
                  </div>
                </div>
                <div className="row mb-2 align-items-center">
                  <label className="col-3">Mật khẩu</label>
                  <div className="col-7">
                    <input ref={passwordI} type="password" className="form-control" name="password" onChange={handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 offset-3">
                    <ul className="list-inline">
                      <li className="list-inline-item"><button type="submit" className="btn btn-primary" onClick={handleSubmit}>Đăng nhập</button></li>
                      <li className="list-inline-item"><button type="button" className="btn btn-secondary" onClick={hotroBtn}>Hỗ trợ</button></li>
                    </ul>
                  </div>
                </div>
                <div className="login-success"></div>
                {errs && <div className="row mb-2">
                  <div className="col-7 offset-3">
                    {errs.errors.map((item, i) => <div key={i} className="text-danger">{item}</div>)}
                  </div>
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;