import React from 'react';
import WordIcon from '../word.png';
import Period from './Period';

const Ketqua_hoatdong_show = props => {

  const { danhmuc, form, setForm, period } = props

  return (
    <div className="container section-main py-3">

      <Period form={form} setForm={setForm} period={period} />

      <div className="card shadow-sm">
        <div className="card-header fw-bold bg-secondary text-white">
          Báo cáo hoạt động của An toàn, vệ sinh viên hàng tháng
        </div>
        <div className="card-body">
          <p className="mb-1 fw-bold">Thông tin người báo cáo:</p>
          <table>
            <tbody>
              <tr>
                <td width={260}>- Họ và tên ATVS viên:</td>
                <td className="fw-bold">{danhmuc.name}</td>
              </tr>
              <tr>
                <td width={260}>- Đơn vị:</td>
                <td className="fw-bold">{sessionStorage.getItem('ten_donvi')}</td>
              </tr>
              <tr>
                <td>- Thuộc nhóm/tổ/đội:</td>
                <td>{danhmuc.todoi}</td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ chết người:</td>
                <td>{danhmuc.tnld_chet}</td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ nặng:</td>
                <td>{danhmuc.tnld_nang}</td>
              </tr>
              <tr>
                <td>- Số vụ TNLĐ nhẹ:</td>
                <td>{danhmuc.tnld_nhe}</td>
              </tr>
            </tbody>
          </table>
          <div className="row mb-2 row">
            <div className="col-6 align-self-end"><p className="fw-bold">Nội dung báo cáo:</p></div>
            <div className="col-6 text-end">
              <a
                className="btn btn-light border fw-bold"
                href={`/download/baocao_hoatdong_atvs_thang/${form.donvi_id}/${form.period_id}`}
              ><img src={WordIcon} width={22} /> Tải báo cáo</a></div>
          </div>
          <table width="100%" border="1" className="table table-striped">
            <thead>
              <tr className="bg-secondary text-white">
                <th>STT</th>
                <th>Nội dung công việc</th>
                <th>Thời gian thực hiện</th>
                <th>Kết quả</th>
                <th>Ghi chú (nếu có)</th>
              </tr>
            </thead>
            <tbody>
              {danhmuc.chitiet.map(item => <tr key={item.id}>
                <td valign="middle" className="text-center">{item.name}</td>
                <td valign="middle" width="30%">{item.noidung_cv}</td>
                <td valign="middle" width="23.3%">{item.thoigian_th}</td>
                <td valign="middle" width="23.3%">{item.ketqua}</td>
                <td valign="middle" width="23.3%">{item.ghichu}</td>
              </tr>)}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Ketqua_hoatdong_show;