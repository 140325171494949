import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Listdonvi from '../partials/list/Listdonvi';
import Listpheduyet from '../partials/list/Listpheduyet';
import Listthamdinh from '../partials/list/Listthamdinh';
import Loading from '../partials/Loading';
import { useLocation } from 'react-router-dom';
import Listkiemtra from '../partials/list/Listkiemtra';

const List = () => {
  const [kys, setKys] = useState([])
  const [years, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const fetchYears = () => {
    return axios.get('/api/period/list_years?full=true')

  }

  const location = useLocation()

  const date = new Date()

  const redMonth = (m, y, i) => {
    return m == i.thang && y == i.nam && 'text-danger'
  }

  useEffect(() => {
    axios.get('/api/period')
      .then(res => {
        setKys(res.data)
      })

    fetchYears()
      .then(response => {
        setYearList(response.data);
      });
  }, [])

  if (!kys.length)
    return <Loading />

  switch (parseInt(sessionStorage.getItem('role_id'))) {
    case 1:
      return <Listdonvi selectedYear={selectedYear} setSelectedYear={setSelectedYear} years={years} kys={kys} thisMonth={date.getMonth() + 1} thisYear={date.getFullYear()} redMonth={redMonth} />
    case 2:
      return <Listpheduyet selectedYear={selectedYear} setSelectedYear={setSelectedYear} years={years} kys={kys} thisMonth={date.getMonth() + 1} thisYear={date.getFullYear()} redMonth={redMonth} />
    case 3:
      if (location.pathname === '/danhgia')
        return <Listthamdinh selectedYear={selectedYear} setSelectedYear={setSelectedYear} years={years} kys={kys} thisMonth={date.getMonth() + 1} thisYear={date.getFullYear()} redMonth={redMonth} />
      if (location.pathname === '/kiemtra')
        return <Listkiemtra selectedYear={selectedYear} setSelectedYear={setSelectedYear} years={years} />
  }
};

export default List;