import React from 'react';

const Period = props => {

  const { form, setForm, period } = props
  
  return (
    <div className="row d-flex justify-content-end">
      <div className="col-3">
        <div className="mb-3">
          <div className="mb-1 fw-bold">
            Chọn kỳ báo cáo
          </div>
          <select className="form-select" defaultValue={form.period_id} onChange={e => {

            setForm({ ...form, period_id: e.target.value })
          }}>
            {period.map(item => <option key={item.id} value={item.id}>Tháng {item.thang} năm {item.nam}</option>)}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Period;