import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../partials/Loading';
import _ from 'lodash';

const DanhGiaHoatDongATVSV = () => {

  const { donvi, period_id } = useParams()

  const [danhmuc, setDanhmuc] = useState([])
  const [period, setPeriod] = useState([])

  const [form, setForm] = useState({
    fullname: sessionStorage.getItem('fullname'),
    chitiet: []
  });

  const fetchPeriod = () => {

    return axios.get('/api/period/' + period_id)
  }

  const fetchDanhMuc = () => {

    return axios.get('/api/chamdiem_hoatdong_atvs')
  }

  useEffect(() => {

    fetchPeriod().then(res => setPeriod(res.data))
    fetchDanhMuc().then(res => {

      let arr = []

      res.data.map(item => {

        arr.push({ ...item, diem: 0 })
      })

      setDanhmuc(arr)
    })

  }, [])

  const handleChange = (e, id, diem_chuan) => {

    if (diem_chuan !== 0 && e.target.value > diem_chuan)
      e.target.value = diem_chuan

    if (e.target.value < 0) e.target.value = 0

    const item = _.find(danhmuc, function (item) {

      return item.id == id
    })

    danhmuc.splice(_.indexOf(danhmuc, item), 1, { ...item, diem: e.target.value })

    setDanhmuc([...danhmuc])
  }

  const handleSubmit = e => {

    e.preventDefault()

    if (window.confirm('Xác nhận chấm ' + sum + ' điểm cho đơn vị này?'))
      axios.post('/api/chamdiem_hoatdong_atvs', {
        ...form,
        donvi_id: donvi,
        period_id: period_id,
        chitiet: [...danhmuc]
      })
        .then(response => {

          if (response.data.success) {

            alert('Chấm điểm thành công!');

            window.location.href = '/'
          }
        })
  }

  if (!danhmuc.length) return <Loading />

  const sum = danhmuc.map(item => {
    return parseInt(item.diem)
  }).reduce((a, b) => a + b)

  return (
    <div className="container section-main py-3">

      <div className="card shadow-sm">
        <div className="card-header fw-bold bg-secondary text-white">
          Bảng chấm điểm đánh giá hoạt động an toàn, vệ sinh viên tháng {period.thang} năm {period.nam}
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row mb-2 row">
              <div className="col-6 align-self-end"><p className="fw-bold">Danh mục tiêu chí:</p></div>
              <div className="col-6 text-end"><button className="btn btn-success">Chấm điểm</button></div>
            </div>
            <table width="100%" border="1" className="table">
              <thead>
                <tr className="bg-secondary text-white">
                  <th>STT</th>
                  <th>Nội dung</th>
                  <th className="text-center">Điểm chuẩn</th>
                  <th className="text-center">Điểm chấm <span className="text-danger">(*)</span></th>
                </tr>
              </thead>
              <tbody>
                {danhmuc.map(item => <tr key={item.id} className={item.is_category && 'fw-bold bg-secondary text-light'}>
                  <td valign="middle" className="text-center">{item.name}</td>
                  <td valign="middle" width="60%">{item.noidung_cv}</td>
                  <td valign="middle" className="text-center" width="20%">{item.diem_chuan}</td>
                  <td valign="middle" className="text-center" width="20%">{!item.is_category &&
                    <div className="d-flex justify-content-center">
                      <input
                        name="diem[]"
                        className="form-control"
                        type="number"
                        defaultValue={0}
                        maxLength={3}
                        // max={item.diem_chuan}
                        style={{ width: 75 }}
                        onInput={e => handleChange(e, item.id, item.diem_chuan)}
                      />
                    </div>}</td>
                </tr>)}
              </tbody>
              <tfoot className="fw-bold">
                <tr>
                  <td></td>
                  <td>Tổng điểm</td>
                  <td></td>
                  <td className="text-center">{sum}</td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DanhGiaHoatDongATVSV;